<template>
  <div class="container">
    <a-page-header title="外包结算" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form">
        <a-divider orientation="left">选择服务商</a-divider>
        <a-form-item :wrapperCol="{lg: { span: 24 }, sm: { span: 24 }}">
          <a-alert type="info" show-icon v-if="companyInfo.id">
            <div slot="message">
              温馨提示：该服务商含税服务税率为<span style="color: red">{{companyInfo.hs_money}}</span>
              仅支持单人单月个人营收结算额度<span style="color: red">{{companyInfo.month_money}}元/月</span>，如需提额，请联系您的客户专家
            </div>
          </a-alert>
          <div class="user-list-item">
            <a-input type="hidden" v-decorator="['company_id', {rules: [{required: true, message: '请选择服务商'}]}]" />
            <div class="user-item" :class="item.id == companyInfo.id ? 'active' : ''" v-for="item in userList" :key="item.id" @click="chooseCompany(item)">
              <div class="user-info">
                <div class="header"><a-avatar :src="item.header" icon="user" :size="64" /></div>
                <div class="info">
                  <div class="username">{{item.real_name}}</div>
                  <div class="company-name">{{item.company_name}}</div>
                </div>
              </div>
              <div class="detail-item">
                <div class="name">本月剩余可接包量：</div>
                <div class="value red">¥ {{item.month_money - item.y_month}}</div>
              </div>
              <div class="detail-item">
                <div class="name">含税服务费率：</div>
                <div class="value">{{item.hs_money | formateTax()}}</div>
              </div>
              <div class="lable-item">
                <div class="lable" v-for="(lableItem, index) in item.lable_name" :key="index">{{lableItem.name}}</div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-divider orientation="left">填写信息</a-divider>
        <a-form-item label="项目名称">
          <a-input type="text" v-decorator="['task_name', {rules: [{required: true, message: '请输入项目名称'}]}]" />
        </a-form-item>
        <a-form-item label="项目类型">
          <a-input :read-only="true" type="text" @click="handleShowGoodsModal" :value="goodsInfo.title" />
          <a-input type="hidden" v-decorator="['goods_id', {rules: [{required: true, message: '请选择项目类型'}]}]" />
        </a-form-item>
        <a-form-item label="项目周期">
          <a-input type="text" v-decorator="['day', {rules: [{required: true, message: '请输入项目类型'}]}]" suffix="天" />
        </a-form-item>
        <a-form-item label="开始时间">
          <a-date-picker placeholder="" style="width: 100%;" @change="handleStartTimeChange" />
          <a-input type="hidden" v-decorator="['start_time', {rules: [{required: true, message: '请选择开始时间'}]}]" />
        </a-form-item>
        <a-form-item label="项目服务费">
          <a-input type="text" v-decorator="['task_money', {rules: [{required: true, message: '请输入项目服务费'}]}]" suffix="元" />
        </a-form-item>
        <a-form-item label="项目标签">
          <a-checkbox-group @change="handleLableChange">
            <a-checkbox :value="item.id" v-for="item in lableData" :key="item.id" :checked="item.checked">{{item.name}}</a-checkbox>
          </a-checkbox-group>
          <a-input
            type="hidden"
            v-decorator="['lable', {initialValue: lable, rules: [{required: true, message: '请输入项目标签'}]}]"
          />
        </a-form-item>
        <a-form-item label="附件">
          <a-upload
            name="photo"
            action="/common/upload"
            @change="handleUploadChange"
          >
            <a-button> <a-icon :type="loading ? 'loading' : 'upload'" /> 上传附件 </a-button>
          </a-upload>
          <a-input type="hidden" v-decorator="['fj']" />
        </a-form-item>
        <a-form-item label=" " :colon="false">
          <a-button :loading="submitBtn" :disabled="submitBtn" size="large" type="primary" @click="handleSubmit">发布需求</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal v-model="showGoodsModal" title="选择项目类型" :footer="null" width="80%" :bodyStyle="{height: 'calc(80vh - 48px - 55px)', overflow: 'auto'}">
      <a-table row-key="id" :columns="columns" :data-source="goodsList" :pagination="false">
        <template slot="tax" slot-scope="text">
          {{text | formateTax()}}
        </template>
        <template slot="operate" slot-scope="text, item">
          <a-button v-if="!item.children" size="small" type="primary" @click="chooseGoods(item)">确定</a-button>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>

import { userList, createTask, getGoods, lableData } from '@/utils/api'
const columns = [
  {
    title: '编码',
    dataIndex: 'code'
  },
  {
    title: '类目、类型',
    dataIndex: 'name'
  },
  {
    title: '名称',
    dataIndex: 'title'
  },
  {
    title: '税率',
    dataIndex: 'tax',
    scopedSlots: { customRender: 'tax' },
  },
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'AddTask',
  data() {
    return {
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      userList : [],
      goodsList: [],
      goodsInfo: {},
      companyInfo: {},
      loading: false,
      fileList: [],
      submitBtn: false,
      columns,
      showGoodsModal: false,
      lableData: [],
      lable: ''
    }
  },
  mounted() {
    this.getUserList()
    this.getLableData()
  },
  filters: {
    formateTax(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val * 100 + '%'
      }
      return ''
    }
  },
  methods: {
    getLableData () {
      lableData({
        type: 1,
        switch: 4
      }).then((res) => {
        const data = res.info || []
        let checkedLable = this.lable
        if (checkedLable && data.length > 0) {
          const lableArr = checkedLable.split(',')
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            for (let i = 0; i < lableArr.length; i++) {
              const lable = lableArr[i];
              if (item.id == lable) {
                data[index].checked = true
                break
              }
            }
          }
        }
        this.lableData = data
      })
    },
    handleLableChange (e) {
      let lable = ''
      if (e.length > 0) {
        lable = ','
        for (let index = 0; index < e.length; index++) {
          const item = e[index];
          lable += item + ','
        }
      }
      this.lable = lable
    },
    getGoodsList() {
      const company_id = this.companyInfo.id
      if (!company_id) {
        this.$message.error('请先选择服务商')
        return
      }
      getGoods({
        switch: 4,
        y_id: company_id
      }).then((res) => {
        this.goodsList = res.info
      })
    },
    getUserList() {
      userList({
        role_id: 2,
        page: 1,
        pageCount: 1000
      }).then((res) => {
        this.userList = res.user
        if (res.user.length > 0) {
          this.chooseCompany(res.user[0])
        }
      })
    },
    chooseCompany(company) {
      this.companyInfo = company
      this.form.setFieldsValue({
        company_id: company.id
      })
    },
    handleStartTimeChange(date, dateString) {
      this.form.setFieldsValue({
        start_time: dateString
      })
    },
    handleUploadChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        const fjArr = []
        for (let index = 0; index < res.fileList.length; index++) {
          const element = res.fileList[index];
          fjArr.push(element.response.info)
        }
        const fj = fjArr.join(',')
        this.form.setFieldsValue({
          fj
        })
        this.loading = false
      }
    },
    handleShowGoodsModal() {
      this.showGoodsModal = true
      this.getGoodsList()
    },
    chooseGoods(item) {
      this.goodsInfo = item
      this.form.setFieldsValue({
        goods_id: item.id
      })
      this.showGoodsModal = false
    },
    handleSubmit() {
      const _this = this
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitBtn = true
          createTask(values).then(res => {
            this.submitBtn = false
            if (res.error == 0) {
              this.$success({
                title: '提示',
                content: '发布需求成功',
                okText: '确定',
                onOk() {
                  _this.$router.push({path: '/index/taskList'})
                }
              })
            } else {
              this.$error({
                title: '提示',
                content: res.info,
                okText: '确定',
              })
            }
          }).catch(() => {
            this.submitBtn = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
.user-list-item {
  padding: 0 10px 20px;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  .user-item {
    width: 350px;
    height: 260px;
    padding: 20px 10px;
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .1);
    margin: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.active {
      border-color: #70a0f8;
      &::before {
        content: ' ';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 40px solid #448ef7;
        border-left: 40px solid transparent;
      }
      &::after {
        content: '✓';
        position: absolute;
        font-size: 20px;
        width: 20px;
        line-height: 30px;
        text-align: center;
        right: 0;
        bottom: 0;
        color: #fff;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .info {
        flex: 1;
        padding-left: 12px;
        line-height: 24px;
        .username {
          font-size: 16px;
          color: #333;
        }
        .company-name {
          color: #999;
          font-size: 12px;
        }
      }
    }
    .detail-item {
      display: flex;
      font-size: 14px;
      padding: 5px 0;
      line-height: 18px;
      .name {
        color: #999;
      }
      .value {
        color: #333;
        &.red {
          color: red;
        }
      }
    }
    .lable-item {
      padding-top: 10px;
      display: flex;
      flex-flow: wrap;
      max-height: 328px;
      overflow: hidden;
      .lable {
        padding: 0 6px;
        line-height: 18px;
        border-radius: 3px;
        font-size: 12px;
        background-color: #ebf3fd;
        color: #438df3;
        margin: 0 5px 10px 0;
      }
    }
  }
}
</style>
